import React from "react";

function Info() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="90"
      height="90"
      fill="none"
      viewBox="0 0 90 90"
    >
      <path
        fill="#1D1D1D"
        d="M45 0a45 45 0 100 90 45 45 0 000-90zm0 15a5 5 0 110 10 5 5 0 010-10zm8 57H37a5 5 0 010-10h3V42h-3a5 5 0 010-10h11a2 2 0 012 2v28h3a5 5 0 010 10z"
      />
    </svg>
  );
}

export default Info;
