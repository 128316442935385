
export function rando(arr) {
    return arr[Math.floor(Math.random() * arr.length)];
  }


  export function getSvg(slug){
      switch (slug) {
        case 'instagram':
      return '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 37 32"> <path fill="#2B2B2B" d="M18.504 7.77c-5.25 0-9.484 3.652-9.484 8.178 0 4.527 4.234 8.178 9.484 8.178s9.484-3.651 9.484-8.178c0-4.526-4.234-8.178-9.484-8.178zm0 13.495c-3.392 0-6.166-2.384-6.166-5.317 0-2.932 2.765-5.316 6.166-5.316 3.4 0 6.166 2.384 6.166 5.316 0 2.933-2.773 5.317-6.166 5.317zm12.084-13.83c0 1.061-.99 1.908-2.212 1.908-1.23 0-2.212-.854-2.212-1.907s.99-1.908 2.212-1.908c1.222 0 2.212.855 2.212 1.908zm6.282 1.937c-.14-2.555-.817-4.819-2.988-6.683C31.719.824 29.094.24 26.132.112c-3.055-.15-12.209-.15-15.263 0-2.955.121-5.58.705-7.75 2.57C.946 4.545.278 6.81.13 9.364c-.173 2.633-.173 10.526 0 13.16.14 2.555.817 4.818 2.988 6.683 2.171 1.865 4.788 2.448 7.75 2.577 3.055.149 12.209.149 15.263 0 2.963-.122 5.588-.705 7.75-2.577 2.164-1.865 2.84-4.128 2.989-6.683.173-2.634.173-10.52 0-13.153zM32.924 25.35c-.643 1.395-1.89 2.47-3.516 3.032-2.435.833-8.213.64-10.904.64-2.69 0-8.477.186-10.904-.64-1.618-.555-2.864-1.63-3.516-3.032-.966-2.1-.743-7.081-.743-9.402 0-2.32-.215-7.31.743-9.402.644-1.395 1.89-2.47 3.516-3.032 2.435-.833 8.213-.64 10.904-.64 2.691 0 8.477-.185 10.904.64 1.618.555 2.864 1.63 3.516 3.032.966 2.1.743 7.082.743 9.402 0 2.32.223 7.31-.743 9.402z" /> </svg>'
        
        case 'github':
          return '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 51 50"> <path fill="#1D1D1D" d="M25.5 0C11.411 0 0 11.418 0 25.5c0 11.269 7.306 20.825 17.436 24.193 1.275.24 1.742-.548 1.742-1.226 0-.606-.021-2.21-.032-4.335-7.093 1.538-8.589-3.421-8.589-3.421-1.16-2.943-2.837-3.73-2.837-3.73-2.31-1.58.179-1.549.179-1.549 2.56.179 3.905 2.627 3.905 2.627 2.274 3.9 5.97 2.773 7.427 2.12.23-1.648.886-2.773 1.615-3.41-5.663-.638-11.615-2.83-11.615-12.601 0-2.784.988-5.058 2.624-6.843-.287-.644-1.147-3.236.223-6.749 0 0 2.136-.684 7.013 2.614 2.04-.567 4.207-.848 6.375-.86 2.168.012 4.335.293 6.375.86 4.845-3.298 6.98-2.614 6.98-2.614 1.371 3.513.51 6.105.256 6.75 1.625 1.784 2.613 4.058 2.613 6.842 0 9.796-5.96 11.953-11.634 12.58.892.765 1.721 2.329 1.721 4.717 0 3.413-.032 6.154-.032 6.983 0 .67.447 1.466 1.753 1.211C43.701 46.314 51 36.752 51 25.5 51 11.418 39.582 0 25.5 0" /> </svg>';
        
        case 'twitter':
          return '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 49 41"> <path fill="#1D1D1D" d="M43.963 10.52c.031.435.031.87.031 1.306 0 13.276-10.104 28.572-28.573 28.572-5.69 0-10.975-1.648-15.421-4.508.808.093 1.586.125 2.425.125 4.695 0 9.017-1.586 12.468-4.291a10.06 10.06 0 01-9.39-6.965c.622.094 1.244.156 1.897.156.901 0 1.803-.124 2.643-.342a10.044 10.044 0 01-8.053-9.856v-.124a10.115 10.115 0 004.54 1.274 10.036 10.036 0 01-4.478-8.363c0-1.866.497-3.576 1.368-5.068a28.546 28.546 0 0020.707 10.509 11.331 11.331 0 01-.249-2.3C23.878 5.11 28.355.601 33.92.601c2.892 0 5.504 1.212 7.338 3.17a19.771 19.771 0 006.374-2.424 10.016 10.016 0 01-4.415 5.534c2.02-.218 3.98-.777 5.783-1.555a21.588 21.588 0 01-5.037 5.193z" /> </svg>'
     
      case 'linkedin':
      return '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 51 52"> <path fill="#1D1D1D" d="M43.45 44.313h-7.552V32.246c0-2.877-.058-6.58-3.936-6.58-3.938 0-4.539 3.131-4.539 6.368v12.279h-7.552V19.5h7.255v3.382h.097c1.014-1.95 3.479-4.008 7.162-4.008 7.652 0 9.067 5.135 9.067 11.819v13.62h-.002zM11.34 16.105c-2.43 0-4.384-2.007-4.384-4.474 0-2.466 1.955-4.47 4.384-4.47 2.423 0 4.386 2.004 4.386 4.47 0 2.467-1.966 4.474-4.386 4.474zm3.787 28.208H7.554V19.5h7.574v24.813zM47.228 0H3.763A3.753 3.753 0 000 3.746v44.508A3.752 3.752 0 003.763 52h43.459C49.3 52 51 50.325 51 48.254V3.746C51 1.677 49.3 0 47.222 0h.006z" /> </svg>'
        
      case 'email':
        return '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 49 37"> <path fill="#1D1D1D" d="M48.072 12.26c.373-.296.928-.019.928.45v19.571a4.595 4.595 0 01-4.594 4.594H4.594A4.595 4.595 0 010 32.281V12.72c0-.479.546-.747.928-.45 2.144 1.665 4.986 3.78 14.748 10.872 2.02 1.473 5.426 4.574 8.824 4.555 3.417.029 6.89-3.139 8.833-4.555 9.762-7.092 12.595-9.217 14.739-10.882zM24.5 24.625c2.22.038 5.417-2.794 7.025-3.962 12.7-9.216 13.666-10.02 16.595-12.317.555-.43.88-1.1.88-1.809V4.72A4.595 4.595 0 0044.406.125H4.594A4.595 4.595 0 000 4.719v1.818c0 .708.325 1.369.88 1.809 2.929 2.287 3.896 3.1 16.595 12.317 1.608 1.168 4.805 4 7.025 3.962z" /> </svg>'
        case 'codepen':
          return '<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000"><path d="M975.4 320.4L518.1 15.5c-11-7.3-25.3-7.3-36.2 0L24.5 320.4c-9.1 6-14.5 16.3-14.5 27.2v304.9c0 10.9 5.5 21.1 14.5 27.2l457.3 304.9c5.5 3.7 11.8 5.5 18.1 5.5 6.3 0 12.6-1.8 18.1-5.5l457.3-304.9c9.1-6 14.5-16.3 14.5-27.2V347.6c.2-10.9-5.3-21.2-14.4-27.2zM500 613.2L330.2 500 500 386.8 669.7 500 500 613.2zm32.6-283.1V103.7l365.8 243.9-169.8 113.2-196-130.7zm-65.3 0l-196 130.7-169.8-113.2 365.8-243.9v226.4zM212.4 500L75.3 591.4V408.6L212.4 500zm58.9 39.3l196 130.7v226.4L101.5 652.5l169.8-113.2zm261.3 130.6l196-130.7 169.8 113.2-365.8 243.9V669.9zM787.5 500l137.1-91.4v182.8L787.5 500z"/></svg>'
        case 'stackoverflow':
          return '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120"><path d="M84.4 93.8V70.6h7.7v30.9H22.6V70.6h7.7v23.2z" style="fill:#bcbbbb"/><path d="M38.8 68.4l37.8 7.9 1.6-7.6-37.8-7.9-1.6 7.6zm5-18l35 16.3 3.2-7-35-16.4-3.2 7.1zm9.7-17.2l29.7 24.7 4.9-5.9-29.7-24.7-4.9 5.9zm19.2-18.3l-6.2 4.6 23 31 6.2-4.6-23-31zM38 86h38.6v-7.7H38V86z" style="fill:#f48023"/></svg>'
        default:
          break;
      }

  }